<template>
    <v-app>     
       <dashboard-Core-App-Bar />
      <dashboard-core-drawer />
      <dashboard-core-view />
      <dashboard-core-settings />
    </v-app>
  </template>
  
  <script>
  export default {
    name: "DashboardIndex",
    components: {   
      DashboardCoreAppBar: () => import("./components/vecprovince/AppBar"),
      DashboardCoreDrawer: () => import("./components/vecprovince/Drawer"), 
      DashboardCoreSettings: () => import("./components/vecprovince/Settings"),
      DashboardCoreView: () => import("./components/vecprovince/View"),
    },
    data: () => ({   
      loginuser: JSON.parse(sessionStorage.getItem('user')) || 0,
      
    }),
      mounted() {
        let user = JSON.parse(sessionStorage.getItem('user')) || 0
        if (user.user_status != 'G')
          this.$router.push('/')
      },
          methods: {
        home() {
          this.$router.push('vecprovince')
        },
        logout() {
          sessionStorage.clear()
          this.$router.push('/')
        }
      }
  };
  </script>
  <style>
  .text_google {
    font-family: "Kanit", sans-serif;
  }
  .v-data-table thead th {
    font-size: 16px !important;
  }
  
  .table thead th {
    font-size: 16px !important;
  }
  
  </style>
  